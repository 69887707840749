select,
input[type="checkbox"]:hover {
    cursor: pointer;
}

.form-label {
    font-weight: 700;
}

form {
    &.personal-info-form {
        .table-personal-info {
            tr {
                &:first-child td {
                    border-top: 0px;
                }
                &:last-child td {
                    border-bottom: 0px;
                }
                td {
                    vertical-align: middle;
                }
            }
        }
    }
}

.form-control-color {
    width: 3rem;
    height: auto;
    padding: .375rem;
}